import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ConversasList.css';
import { useAuth } from '../auth/AuthContext';

const ConversasList = ({ idUsuario, idCliente }) => {
    const [conversas, setConversas] = useState([]);
    const [resumoConversas, setResumoConversas] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [conversasPerPage] = useState(4);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [expandedGptRunIds, setExpandedGptRunIds] = useState({});
    const [selectedIdAssistente, setSelectedIdAssistente] = useState(null);
    const observer = useRef();
    const { token } = useAuth();

    // Fetch conversations from the backend
    const fetchConversas = useCallback(async (page, idAssistente) => {
        if (!idAssistente || page > totalPages) return; // Não buscar se não houver assistente selecionado
        setLoading(true);
        try {
            const response = await fetch(
                `/conversas?page=${page}&limit=${conversasPerPage}` +
                (idUsuario ? `&idUsuario=${idUsuario}` : '') +
                (idCliente ? `&idCliente=${idCliente}` : '') +
                (idAssistente ? `&idAssistente=${idAssistente}` : ''),
                { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro na requisição');
            }
            const data = await response.json();
            setConversas(prevConversas => (page === 1 ? data.conversas : [...prevConversas, ...data.conversas]));
            setTotalPages(data.totalPages);
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoading(false);
        }
    }, [idUsuario, conversasPerPage, totalPages, idCliente, token]);

    // Fetch summary of conversations grouped by assistant
    const fetchResumoConversas = useCallback(async () => {
        try {
            const response = await fetch(
                `/conversas/resumo` + (idUsuario ? `?idUsuario=${idUsuario}` : '') + (idCliente ? (idUsuario ? '&' : '?') + `idCliente=${idCliente}` : ''),
                { method: 'GET', headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro na requisição');
            }
            const data = await response.json();
            setResumoConversas(data.resumoConversas); // Atualiza com a lista correta
        } catch (err) {
            console.error(err.message);
        }
    }, [idUsuario, idCliente, token]);

    useEffect(() => {
        if (idUsuario || idCliente) {
            fetchResumoConversas(); // Sempre buscar o resumo
        }
    }, [idUsuario, idCliente, fetchResumoConversas]);

    useEffect(() => {
        if (selectedIdAssistente) {
            fetchConversas(currentPage, selectedIdAssistente); // Buscar conversas só após selecionar um assistente
        }
    }, [selectedIdAssistente, currentPage, fetchConversas]);

    const lastCardElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && currentPage < totalPages) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, currentPage, totalPages]);

    const handleToggleExpand = (gptrunid) => {
        setExpandedGptRunIds(prev => ({ ...prev, [gptrunid]: !prev[gptrunid] }));
    };

    const handleSelectIdAssistente = (idAssistente) => {
        setSelectedIdAssistente(idAssistente);
        setCurrentPage(1); // Reset para a primeira página
        setConversas([]); // Limpa as conversas atuais
    };

    const groupedConversas = conversas.reduce((acc, conversa) => {
        if (conversa.origem === 'function') {
            if (!acc[conversa.gptRunId]) {
                acc[conversa.gptRunId] = [];
            }
            acc[conversa.gptRunId].push(conversa);
        } else {
            acc[conversa.id] = [conversa];
        }
        return acc;
    }, {});

    return (
        <div className="conversas-list-container" style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Lista Resumo à Esquerda */}
            <div className="resumo-list">
                {resumoConversas.length > 0 ? (
                    resumoConversas.map((resumo) => (
                        <div
                            key={resumo.idAssistente}
                            className={`resumo-item ${selectedIdAssistente === resumo.idAssistente ? 'selected' : ''}`}
                            onClick={() => handleSelectIdAssistente(resumo.idAssistente)}
                        >
                            <div className="resumo-inicial">{resumo.nomeAssistente}</div>
                        </div>
                    ))
                ) : (
                    <div className="no-resumo-message"></div>
                )}
            </div>

            <div className="card-conversa-container" style={{ flex: 1 }}>
                {Object.keys(groupedConversas).length === 0 && !loading && (
                    <div className="no-records-message">Nenhuma conversa encontrada.</div>
                )}

                {Object.keys(groupedConversas)
                    .sort((a, b) => {
                        const conversaA = groupedConversas[a][0];
                        const conversaB = groupedConversas[b][0];
                        return conversaB.id - conversaA.id; // Ordenação decrescente
                    })
                    .map((key, index) => {
                        const conversasGroup = groupedConversas[key];
                        const isFunctionGroup = conversasGroup[0].origem === 'function';
                        return (
                            <div
                                key={key}
                                className="card-conversa"
                                ref={index === Object.keys(groupedConversas).length - 1 ? lastCardElementRef : null}
                            >
                                {isFunctionGroup ? (
                                    <div
                                        onClick={() => handleToggleExpand(key)}
                                        className={`mensagem-function ${expandedGptRunIds[key] ? 'expanded' : ''}`}
                                    >
                                        {expandedGptRunIds[key] ? (
                                            conversasGroup.map((conversa) => (
                                                <div key={conversa.id} className="card-conversa-inner">
                                                    <div
                                                        className={`mensagem-entrada ${conversa.origem === 'function' ? 'mensagem-function-entrada' : ''
                                                            }`}
                                                    >
                                                        <span>{conversa.mensagemEntrada}</span>
                                                    </div>
                                                    <div
                                                        className={`mensagem-saida ${conversa.origem === 'function' ? 'mensagem-function-saida' : ''
                                                            }`}
                                                    >
                                                        <span>{conversa.mensagemSaida}</span>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="mensagem-function-title">
                                                <span>functions</span>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="mensagem-entrada">
                                        <div>{conversasGroup[0].mensagemEntrada}</div>
                                    </div>
                                )}
                                {!isFunctionGroup && (
                                    <div className="mensagem-saida">{conversasGroup[0].mensagemSaida}</div>
                                )}
                            </div>
                        );
                    })}

                {loading && <div className="loading-message">Carregando...</div>}
                {currentPage >= totalPages && !loading && conversas.length > 0 && (
                    <div className="end-message">Não existem mais registros</div>
                )}
            </div>
        </div>
    );
};

export default ConversasList;
