import React, { useEffect, useState } from 'react';
import './HomeIntelligence.css';
import { useAuth } from '../auth/AuthContext';

const HomeIntelligence = () => {
    const [inteliCards, setInteliCards] = useState([]);
    const { anonToken } = useAuth(); 

    const getDomain = (url) => {
        try {
            const domain = new URL(url).hostname;
            return domain;
        } catch (e) {
            console.error('Invalid URL:', url);
            return url; 
        }
    };

    useEffect(() => {
        const fetchInteliCards = async () => {
            try {
                const response = await fetch('home/buscaentrevistas', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${anonToken}`, 
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Erro na API: ${response.status}`);
                }

                const data = await response.json();
                setInteliCards(data);
            } catch (error) {
                console.error('Erro ao buscar os cards:', error);
            }
        };

        if (anonToken) {
            fetchInteliCards(); 
        }
    }, [anonToken]); 

    return (
        <div className="intelligence-section">
            <div className="row">
                <span>SOBRE NÓS</span>
                <h1>topGPT</h1>
                <h2><b>Inteligência Artificial Sob Medida para Seu Negócio</b></h2>
                <p>
                    Somos a TopGPT, um hub de soluções inovadoras em Inteligência Artificial. Nosso propósito é transformar o modo como empresas e pessoas interagem com o mundo, tornando processos mais ágeis, inteligentes e eficientes.
                </p>
                <p>
                    Acreditamos que a IA é uma ferramenta poderosa que não apenas resolve problemas do presente, mas também cria oportunidades para o futuro. Estamos comprometidos em oferecer tecnologias que aprendem, analisam e se adaptam às suas necessidades específicas, impactando positivamente sua vida e o sucesso do seu negócio.
                </p>
                <p>
                    Desde chatbots intuitivos até sistemas avançados de análise de dados, trabalhamos para colocar a IA a serviço da sua visão. Abaixo, apresentamos reflexões de grandes personalidades que destacam como a IA está mudando o mundo para melhor:
                </p>
            </div>
            <div className="inteli-cards-container">
                {inteliCards.map((card, index) => (
                    <div
                        key={index}
                        className={`inteli-card ${index % 2 === 0 ? 'inteli-dark-card' : 'inteli-orange-card'}`}
                    >
                        <h4 className="card-quote">"{card.frase}"</h4>
                        <p className="card-author"><b>{card.autor}</b></p>
                        <a
                            href={card.referencia}
                            className="card-reference"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {getDomain(card.referencia)}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomeIntelligence;
