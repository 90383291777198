import React, { useEffect, useRef, useState } from 'react';
import './HomeRecommend.css';
import { useAuth } from '../auth/AuthContext';

const HomeRecommend = () => {
    const containerRef = useRef(null);
    const [sections, setSections] = useState([]);
    const { anonToken } = useAuth();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        const fetchAssistentes = async () => {
            try {
                const response = await fetch('./home/buscadepoimentos', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${anonToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const formattedSections = data.map(assistente => ({
                    star: assistente.estrelas,
                    name: assistente.nome,
                    description: assistente.texto,
                    imageUrl: assistente.urlimagem,
                    backgroundColor: assistente.corfundo,
                    textColor: assistente.corletra,
                }));
                setSections(formattedSections);
            } catch (error) {
                console.error('Error fetching assistentes:', error);
            }
        };

        fetchAssistentes();

        return () => clearTimeout(timer);
    }, [anonToken]);

    const scrollLeft = () => {
        containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    const renderStars = (num) => {
        return '★'.repeat(num) + '☆'.repeat(5 - num);
    };

    return (
        <div className="recommends-section">
            <div className="row">
                <i className="fas fa-quote-right"></i>
                    <span>DEPOIMENTOS</span>
                <h1>Nossas <b>inteligências</b> já estão ajudando várias empresas</h1>
                <p>Veja como nossas soluções estão transformando a experiência dos nossos clientes</p>
            </div>
            <div className="recommend-card-wrapper">
                <button className="arrow arrow-left" onClick={scrollLeft}>&#8249;</button>
                <div className="recommend-card-container" ref={containerRef}>
                    {sections.map((section, index) => (
                        <div
                            className="recommend-card"
                            key={index}
                            style={{ backgroundColor: section.backgroundColor, color: section.textColor }}
                        >
                            {section.imageUrl ? (
                                <img src={section.imageUrl} alt={section.name} className="recommend-card-image" />
                            ) : (
                                <div className="recommend-card-placeholder">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="48"
                                        height="48"
                                        fill="#ccc"
                                    >
                                        <path d="M12 12c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0 2c-3.33 0-10 1.67-10 5v2h20v-2c0-3.33-6.67-5-10-5z" />
                                    </svg>
                                </div>
                            )}
                            <div className="recommend-card-content">
                                <p className="recommend-stars">{renderStars(section.star)}</p>
                                <p className="recommend-description">{section.description}</p>
                            </div>
                            <p className="recommend-name">{section.name}</p>
                        </div>
                    ))}
                </div>
                <button className="arrow arrow-right" onClick={scrollRight}>&#8250;</button>
            </div>
        </div>
    );
};

export default HomeRecommend;
