import React from 'react';
import './HomeTrust.css';

const HomeTrust = () => {
    return (
        <div className="trust-section">
            <div className="row">
                <span>UMA ESCOLHA QUE FAZ A DIFERENÇA</span>
                <h1>Por que confiar na <b>TopGPT</b></h1>
                <p>
                    A TopGPT é mais do que uma solução de inteligência artificial; é um parceiro estratégico para o seu negócio.
                    Combinando tecnologia de ponta, expertise em IA e um compromisso inabalável com a inovação, oferecemos
                    ferramentas que não apenas automatizam processos, mas também impulsionam o crescimento e a eficiência.
                </p>
                <p>
                    Aqui estão os principais motivos que fazem da TopGPT a escolha ideal para transformar sua empresa:
                </p>
            </div>
            <div className="trust-cards-container">
                <div className="trust-card trust-dark-card">
                    <h3>1.</h3>
                    <p>Atendimento Personalizado e Consultoria Especializada</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>2.</h3>
                    <p>Soluções Escaláveis para Negócios de Qualquer Tamanho</p>
                </div>
                <div className="trust-card trust-dark-card">
                    <h3>3.</h3>
                    <p>Foco na Segurança e Proteção de Dados</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>4.</h3>
                    <p>Investimento em Pesquisa e Desenvolvimento Contínuo</p>
                </div>
                <div className="trust-card trust-dark-card">
                    <h3>5.</h3>
                    <p>Parcerias Estratégicas com Grandes Nomes da Indústria</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>6.</h3>
                    <p>Resultados Comprovados por Nossos Clientes</p>
                </div>
            </div>
        </div>
    );
};

export default HomeTrust;
