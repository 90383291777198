import React, { useEffect, useRef, useState } from 'react';
import './HomeProduct.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const HomeProduct = () => {
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const [sections, setSections] = useState([]);
    const { anonToken } = useAuth();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        const fetchAssistentes = async () => {
            try {
                const response = await fetch('./home/buscaassistentes', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${anonToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const formattedSections = data.map(assistente => ({
                    title: assistente.nome,
                    description: assistente.descricao,
                    imageUrl: assistente.urlImagem,
                    textBtn: assistente.valor > 0 ? 'Comece já' : 'Teste grátis',
                    assist: assistente.nome,
                }));
                setSections(formattedSections);
            } catch (error) {
                console.error('Error fetching assistentes:', error);
            }
        };

        fetchAssistentes();

        return () => clearTimeout(timer);
    }, [anonToken]);

    const scrollLeft = () => {
        containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    return (
        <div className="products-section">
            <div className="row">
                <span>CONHEÇA NOSSOS PRODUTOS</span>
                <h1>O futuro já chegou</h1>
                <h2>E nós oferecemos produtos de Inteligência Artificial que <b>Transformam Negócios</b></h2>
            </div>
            <div className="product-card-wrapper">
                <button className="arrow arrow-left" onClick={scrollLeft}>&#8249;</button>
                <div className="product-card-container" ref={containerRef}>
                    {sections.map((section, index) => (
                        <div className="product-card" key={index}>
                            <img src={section.imageUrl} alt={section.title} className="product-card-image" />
                            <div className="product-card-content">
                                <h2>{section.title}</h2>
                                <p>{section.description}</p>
                                <button className="btn btn-primary" onClick={() => navigate(`./newuser/${section.assist}`)}>
                                    {section.textBtn}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="arrow arrow-right" onClick={scrollRight}>&#8250;</button>
            </div>
        </div>
    );
};

export default HomeProduct;
