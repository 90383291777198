import React from 'react';
import HomeFooter from '../objects/HomeFooter';

const PrivacyPolicy = () => {
    return (
        <div className="page-container">
            <div className="intelligence-section">
                <div className="col">
                    <h1>Política de Privacidade</h1>
                    <p><strong>Data de Vigência:</strong> 02/06/2025</p>

                    <h2>1. Coleta de Informações</h2>
                    <p>
                        Coletamos informações fornecidas diretamente por você, como nome, e-mail, e outros dados inseridos no uso do aplicativo. Também podemos coletar informações automaticamente, como o endereço IP, tipo de dispositivo, e comportamento de uso do aplicativo.
                    </p>

                    <h2>2. Uso das Informações</h2>
                    <p>
                        As informações coletadas automaticamente são utilizadas apenas e exclusivamente para melhorar o funcionamento do aplicativo, personalizar a experiência do usuário, fornecer suporte ao cliente e enviar notificações relevantes.
                    </p>

                    <h2>3. Compartilhamento de Informações</h2>
                    <p>
                        Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para cumprir a lei.
                    </p>

                    <h2>4. Proteção das Informações</h2>
                    <p>
                        Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Nosso ambiente está em conformidade com a Lei Geral de Proteção de Dados (LGPD), garantindo que seus direitos à privacidade e à proteção de dados pessoais sejam respeitados em todas as fases de tratamento das informações.
                    </p>

                    <h2>5. Seus Direitos</h2>
                    <p>
                        Você tem o direito de acessar, corrigir, ou excluir suas informações pessoais armazenadas por nós. Além disso, de acordo com a LGPD, você pode exercer outros direitos, como a portabilidade dos seus dados, o direito de informação sobre compartilhamento de dados, e o direito à revisão de decisões automatizadas. Para exercer esses direitos, entre em contato conosco em <a href="mailto:suporte@topgpt.com.br">suporte@topgpt.com.br</a>.
                    </p>

                    <h2>6. Alterações a Esta Política</h2>
                    <p>
                        Podemos atualizar esta política periodicamente. Qualquer alteração será comunicada através do aplicativo ou por e-mail.
                    </p>

                    <h2>7. Contato</h2>
                    <p>
                        Se você tiver dúvidas sobre esta política de privacidade, entre em contato conosco em <a href="mailto:suporte@topgpt.com.br">suporte@topgpt.com.br</a>.
                    </p>
                </div>
            </div>

            <HomeFooter />
        </div>
    );
};

export default PrivacyPolicy;
